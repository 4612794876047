#assessment-result-tab {
  ul {
    list-style: none;
    margin: 0;
    padding:5px 0 0 30px;
  }
  li {
    font-size: 0.8125rem;
    border-bottom: 1px solid #EBE6D7;
    padding: .35rem 0;
  }
}
