#assessment-result {
  margin-left: -30px;
  margin-right: -30px;
  #paths {
    background: #fff;
    margin-top: -20px;
    padding: 2px;
    width: 100%;
    border-bottom: 4px solid #3157a7;
  }
  &.mild {
    #paths {
      border-color: #36a692;
    }
  }
  &.moderate {
    #paths {
      border-color: #f7c82f;
    }
  }
  &.severe {
    #paths {
      border-color: #db3e4d;
    }
  }
  .nav-link {
    font-weight: bold;
    font-size: 0.8125rem;
    padding-top: 0.85rem;
    padding-bottom: 0.85rem;
    border-color: #fff;
    &#mild {
      background-color: rgba(54, 166, 146, 0.1);
      color: rgba(54, 166, 146, 0.6);
      &.active {
        background-color: #36a692;
        color: #fff;
        border-bottom-color: #36a692;
      }
      &:hover {
        background-color: #36a692;
        color: #fff;
      }
    }
    &#moderate {
      background-color: rgba(247, 200, 47, 0.1);
      color: rgba(247, 200, 47, 0.6);
      &.active {
        background-color: #f7c82f;
        color: #39404e;
        border-bottom-color: #f7c82f;
      }
      &:hover {
        background-color: #f7c82f;
        color: #39404e;
      }
    }
    &#severe {
      background-color: rgba(219, 62, 77, 0.1);
      color: rgba(219, 62, 77, 0.6);
      &.active {
        background-color: #db3e4d;
        color: #fff;
        border-bottom-color: #db3e4d;
      }
      &:hover {
        background-color: #db3e4d;
        color: #fff;
      }
    }
  }
  &.mild .nav-link#mild {
    background-color: #36a692;
    color: #fff;
    border-bottom-color: #36a692;
  }
  &.moderate .nav-link#moderate {
    background-color: #f7c82f;
    color: #39404e;
    border-bottom-color: #f7c82f;
  }
  &.severe .nav-link#severe {
    background-color: #db3e4d;
    color: #fff;
    border-bottom-color: #db3e4d;
  }
  #tabs {
    border-bottom: 1px solid #ebe6d7;
    .nav-link {
      font-weight: normal;
      font-size: 0.75rem;
      padding: 0.75rem 0.25rem;
      text-transform: uppercase;
      color: #39404e;
      cursor: pointer;
      &.active {
        border-radius: 0;
        background: none;
        color: #39404e;
        font-weight: bold;
        border-bottom: 4px solid #3157a7;
      }
      &:hover {
        color: #3157a7;
      }
    }
  }
  &.mild #tabs .nav-link.active {
    border-color: #36a692;
  }
  &.moderate #tabs .nav-link.active {
    border-color: #f7c82f;
  }
  &.severe #tabs .nav-link.active {
    border-color: #db3e4d;
  }
}
