// Import google fonts
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');

// Import font awesome
@import url('../node_modules/@fortawesome/fontawesome-free/css/all.min.css');

// Override bootstrap variables and add any project specific
$white: #fff !default;
$gray-100: #F5F5F5;
$gray-200: #EEEFF1;
$gray-400: #BBBDC2;
$gray-500: #BEC0C4;
$gray-700: #5B606A;
$gray-800: #39404E;
$gray-900: #111B2F;
$blue: #3157A7;
$light-blue: #CDD5E8;
$indigo: #172A54;
$red: #DB3E4D;
$orange: #E88C32;
$yellow: #F7C82F;
$light-yellow: #FFFBF0;
$green: #36A692;
$light: $white;
$body-color: $gray-800;
$font-family-sans-serif: 'Open Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-font-weight: 700;
$headings-color: $gray-900;
$h1-font-size: 1.75rem;
$grid-gutter-width: 60px;
$navbar-brand-font-size: 1.125rem;
$navbar-toggler-padding-y: 0;
$navbar-toggler-padding-x: 0;
$navbar-toggler-border-radius: 0;
$navbar-dark-color: $white;
$dropdown-color: $white;
$navbar-dark-hover-color: $light-blue;
$dropdown-bg: $indigo;
$dropdown-border-radius: 0;
$dropdown-box-shadow: 0;
$dropdown-link-color: $white;
$dropdown-link-hover-color: $blue;
$dropdown-link-hover-bg: $light-blue;
$btn-font-size-lg: 0.8125rem;
$btn-font-weight: bold;
$btn-padding-y-lg: 1rem;
$btn-padding-x-lg: 1.25rem;
$nav-tabs-border-color: $white;
$nav-tabs-border-width: 2px;
$nav-tabs-link-hover-border-color: $white;
$nav-tabs-link-active-border-color: $white;

// Import bootstrap scss
@import "../node_modules/bootstrap/scss/bootstrap";

// Global app styles
body {
  background-color: $gray-100;
  padding-top: 76px;
  // need global classes based on current assessment status
  &.mild {
    background-color: #EAF6F4;
  }
  &.moderate {
    background-color: #FFFBF0;
  }
  &.severe {
    background-color: #FBEBED;
  }
}
